<div class="flex main-content mt-[40px]">
  <div *ngIf="labelTexts && iconSet" class="px-4 pt-0 pb-3 lg:py-3">
    <h1 class="text-5xl md:text-6xl">{{labelTexts['contact.hr.heading']}}</h1>
    <div class="row grid grid-cols-1">
      <div class="col-span-12 md:w-[100vw] lg:w-[90vw]">
        <div class="contact-wrap bg-white px-5 rounded-lg md:px-16 py-8">
          <h3 class="text-lg text-black leading-5 font-semibold">{{labelTexts['contact.hr.subheading']}}</h3>
          <p class="sub-line text-smd text-black mb-10 leading-4">{{labelTexts['contact.hr.desc']}}</p>
          <div class="row grid grid-cols-1 lg:grid-cols-2 gap-20">
            <form [formGroup]='contactForm'>
              <div class="form-group" [ngClass]="{ 'is-invalid': form.subject.touched && form.subject.invalid}">
                <label for="Subject">{{labelTexts['contact.hr.subbox']}} <span class="required">*</span></label>
                <input type="text" class="form-control text-sm md:text-smd" id="Subject" formControlName='subject'
                  placeholder="{{labelTexts['contact.ebem.blank.subbox']}}">
                <small class="error-msg text-sm md:text-smd"
                  *ngIf="contactForm.controls['subject'].hasError('required')">
                  {{labelTexts['contact.ebem.blank.subbox']}}
                </small>
                <small class="error-msg text-sm md:text-smd"
                  *ngIf="contactForm.controls['subject'].hasError('maxlength')">
                  Your subject cannot exceed 256 characters.
                </small>
              </div>
              <div class="form-group" [ngClass]="{'is-invalid': form.category.touched && form.category.invalid}">
                <label for="Category">{{labelTexts['contact.hr.catdropdown']}} <span class="required">*</span></label>
                <select class="form-control custom-select text-sm md:text-smd" formControlName='category'>
                  <option value="">Select the Category for your inquiry</option>
                  <option *ngFor="let optionValue of optionValues">
                    {{optionValue.value}}
                  </option>
                </select>
                <small class="error-msg text-sm md:text-smd">{{labelTexts['contact.ebem.blank.catbox']}}</small>
              </div>
              <div class="form-group" [ngClass]="{'is-invalid': form.message.touched && form.message.invalid}">
                <label for="Message">{{labelTexts['contact.hr.msgbox']}} <span class="required">*</span></label>
                <textarea class="form-control text-sm md:text-smd" formControlName='message' rows="8"
                  placeholder="{{labelTexts['contact.ebem.blank.msgbox']}}"></textarea>
                <small class="error-msg text-sm md:text-smd"
                  *ngIf="contactForm.controls['message'].hasError('required')">
                  {{labelTexts['contact.ebem.blank.msgbox']}}
                </small>
                <small class="error-msg text-sm md:text-smd"
                  *ngIf="contactForm.controls['message'].hasError('maxlength')">
                  Your message cannot exceed 1000 characters.
                </small>
              </div>
              <button type="submit" class="btn my-5 py-3.5 px-[50px] lg:py-5 lg:px-[60px]"
                (click)='submitContactForm()'>{{labelTexts['contact.hr.submitbtn']}}</button>
            </form>
            <div class="text-center hidden lg:block justify-items-center">
              <img src="{{iconSet['contact.hr.graphic']}}" class="img-fluid">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>