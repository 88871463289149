<div tourAnchor="investor.dashboard.summary">
    <div [ngClass]="{'card rounded-lg': showBanner}">
        <div [ngClass]="{'card-body p-4': showBanner}">
            <app-info-text-box *ngIf="showBanner"
                [infoText]="'Summary values contain prior quarter data'"></app-info-text-box>
            <div class="grid grid-cols-2 md:grid-cols-4 widget-row md:max-h[135px] ml-0.5 gap-x-4 gap-y-4 md:pr-0"
                *ngIf="investorSummary">
                <div class="card">
                    <div class="card-body">
                        <div class="widget-img">
                            <img src="{{iconSet['icon.commitment']}}" alt="Investor Commitment" class="mt-6">
                        </div>
                        <h2 class="widget-value" data-cy="summaryValue">{{investorSummary.commitment |
                            currencyFormat:investorSummary?.currency}}</h2>
                        <p class="widget-text">Investor Commitment</p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="widget-img">
                            <img src="{{iconSet['icon.investor.contributions']}}" alt="Investor Contributions"
                                class="mt-3">
                        </div>
                        <h2 class="widget-value" data-cy="summaryValue">{{investorSummary.contributions |
                            currencyFormat:investorSummary?.currency}}
                        </h2>
                        <p class="widget-text">Investor Contributions
                        </p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="widget-img">
                            <img src="{{iconSet['icon.investor.distributions']}}" alt="Investor Distributions"
                                class="mt-3">
                        </div>
                        <h2 class="widget-value" data-cy="summaryValue">{{investorSummary.distributions |
                            currencyFormat:investorSummary?.currency}}
                        </h2>
                        <p class="widget-text">Investor Distributions
                        </p>
                    </div>
                </div>
                <div class="card">
                    <div class="card-body">
                        <div class="widget-img">
                            <img src="{{iconSet['icon.investor.nav']}}" alt="Net Asset Value" class="mt-3">
                        </div>
                        <h2 class="widget-value" data-cy="summaryValue">{{investorSummary.nav |
                            currencyFormat:investorSummary?.currency }}</h2>
                        <p class="widget-text">Investor Net Asset Value
                        </p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>