import { ApplicationConfig, ErrorHandler, Injectable, Injector, importProvidersFrom } from '@angular/core';
import { Router, provideRouter } from '@angular/router';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi, withJsonpSupport } from '@angular/common/http';
import { GCMRoutes } from './app.routes';
import { provideAnimations } from '@angular/platform-browser/animations';
import { APOLLO_OPTIONS, Apollo } from 'apollo-angular'
import { HttpLink } from 'apollo-angular/http';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import { Okta } from './shared/okta.service';
import { OktaAuth } from '@okta/okta-auth-js';
import { OKTA_CONFIG, OktaAuthModule } from '@okta/okta-angular';
import { setContext } from '@apollo/client/link/context';
import { provideNgIdleKeepalive } from '@ng-idle/keepalive'
import { EnvironmentVariablesService } from './services/environment-variables.service';
import { ProxyService } from './services/proxy.service';
import { onError } from '@apollo/client/link/error';
import { LoggingService } from './services/logging.service';
import { OktaTokenAuthInterceptor } from './core/http/okta-token-auth-interceptor';
import { GlobalErrorHandler } from './services/global-error-handler';
import { RaygunV2 } from 'raygun4js';
import { BASE_ENV_CONFIG } from '@gcm/gcm-ui-angular';
import { environment } from 'src/environments/environment';

declare const rg4js: RaygunV2

export function setupOktaConfig(okta: Okta) {
  return {
    oktaAuth: okta.getOktaAuth(),
    onAuthRequired: onAuthRequired
  };
}

function onAuthRequired(oktaAuth: OktaAuth, injector: Injector) {
  // Use injector to access any service available within your application
  const router = injector.get(Router);
  let route = router.getCurrentNavigation().finalUrl.toString();

  // Redirect the user to your custom login page
  router.navigate(["/login"], { queryParams: { returnUrl: route } });
}

export function createApollo(httpLink: HttpLink, evService: EnvironmentVariablesService, loggingService: LoggingService): ApolloClientOptions<any> {
  const errorLink = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        loggingService.log(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
      );
    }

    if (networkError) {
      const varnetworkError = JSON.parse(JSON.stringify(networkError));
      varnetworkError.error.errors.forEach(element => {
        loggingService.log(element.message);
      });
      loggingService.log(networkError.message);
    }
  });

  const auth = setContext((operation, context) => {
    return {
      headers: {
        Authorization: `Bearer ${evService.contentful['token']}`,
      },
    };
  });

  const uri = `https://graphql.contentful.com/content/v1/spaces/${evService.contentful['spaceId']}/environments/pre-auth`; // <-- add the URL of the GraphQL server here
  return {
    link: ApolloLink.from([errorLink, auth, httpLink.create({ uri })]),
    cache: new InMemoryCache(),
  };
}

@Injectable()
export class RaygunErrorHandler implements ErrorHandler {
  constructor(private loggingService: LoggingService) {
  }
  handleError(e: any) {
    this.loggingService.log("error", e);
    rg4js('send', {
      error: e,
    });
  }
}

export const appConfig: ApplicationConfig = {
  providers: [
    provideHttpClient(),
    importProvidersFrom(
      OktaAuthModule
    ),
    provideRouter(GCMRoutes),
    {
      provide: OKTA_CONFIG, useFactory: setupOktaConfig,
      deps: [Okta]
    },
    {
      provide: BASE_ENV_CONFIG,
      useValue: environment,
    },
    provideAnimations(),
    {
      provide: APOLLO_OPTIONS,
      useFactory: createApollo,
      deps: [HttpLink, EnvironmentVariablesService, Router, ProxyService],
    },
    Apollo,
    { provide: ErrorHandler, useClass: GlobalErrorHandler },
    {
      provide: ErrorHandler,
      useClass: RaygunErrorHandler
    },
    { provide: HTTP_INTERCEPTORS, useClass: OktaTokenAuthInterceptor, multi: true, deps: [EnvironmentVariablesService, Okta] },
    provideHttpClient(withInterceptorsFromDi(), withJsonpSupport()),
    provideNgIdleKeepalive()
  ]
};