import { Injectable } from '@angular/core';
import { UserService } from './user.service';
import { Router } from '@angular/router';
import { ApiService } from '../core/http/api.service';
import { LoggingService } from './logging.service';

@Injectable({
    providedIn: 'root'
})
export class ActivityTrackingService {
    constructor(
        private userService: UserService,
        private router: Router,
        private apiService: ApiService,
        private loggingService: LoggingService
    ) { }

    //on event, send data to db to store user activity
    userActivityStorage(activity: any) {
        let contextParameters = activity.contextParameter?.map(c => ({ "key": Object.keys(c)[0],  "value": Object.values(c)[0] }))
        let userActivity = {
            "activityName": activity.name,
            "controllerName": this.router.url,
            "actionName": this.getCurrentPage(),
            "userName": this.userService.getUserEmail(),
            "isAdmin": !this.userService.isExternalUser(), //check if aad user is admin or other way to know?
            "applicationName": "ClientScope",
            "contextParameters": contextParameters,
            "sessionId": this.userService.getSessionIdValue()
        }
        this.apiService.updateUserActivity(userActivity).subscribe({
            next: () => {},
            error: (err) => {
                this.loggingService.log("----- User Activity Logging Error -----", err);
            }
          });
    }

    toggledAsOfDate(date) {
        let activity = {
            name: 'As of date',
            contextParameter: [{'date': date.toString()}]
        }
        this.userActivityStorage(activity);
    }

    expandOrCollapseToggled(type, data, table, expanded: boolean = true) {
        let activity = {
          name: `${expanded ? 'Expanded' : 'Collapsed'} ${type}`,
          contextParameter: [{'name': data.name}, {'id': data?.masterId ?? data?.id}, {'table': table}]

        }
        this.userActivityStorage(activity);
    }

    navigateToDashboard(type, data, from) {
        let activity = {
            name: `${type} navigation`,
            contextParameter: [{'name': data.name}, {'id': data?.masterId ?? data?.id}, {'from': from}]
        }
        this.userActivityStorage(activity);
    }

    downloadSnapshot(id, name) {
        let activity = {
            name: 'Downloaded snapshot',
            contextParameter: [{'id': id}, {'name': name}]
        }
        this.userActivityStorage(activity);
    }

    widgetTabChange(widget, tabName) {
        let activity = {
            name: `${widget}`,
            contextParameter: [{'action': 'toggled tab'}, {'name': tabName}]
        }
        this.userActivityStorage(activity);
    }

    tableExpandViewToggle(table, name, expanded: boolean = true) {
        let activity = {
          name: `${expanded ? 'Expanded' : 'Collapsed'} table view`,
          contextParameter: [{'table': table}, {'name': name}]
        }
        this.userActivityStorage(activity);
    }

    exportToExcelClick(table, name) {
        let activity = {
            name: 'Export to excel',
            contextParameter: [{'table': table}, {'name': name}]
        }
        this.userActivityStorage(activity);
    }

    tourServiceStarted(clicked: boolean) {
        let context = ''
        if (clicked) {
            context = 'clicked to start'
        } else {
            context = 'started from login'
        }
        let activity = {
            name: `Tour started`,
            contextParameter: [{'context': context}]
        }
        this.userActivityStorage(activity);
    }

    useFilterTool(filterType, filteredInvestor) {
        let activity = {
          name: 'Selected filter',
          contextParameter: [
            {'filter': filterType}, 
            {'id': filteredInvestor.id}, 
            {'name': filteredInvestor.name}
        ]
        }
        this.userActivityStorage(activity);
    }

    logout() {
        let activity = {
          name: `Log out button clicked`,
          contextParameter: [{'name': this.userService.getUserEmail()}]
        }
        this.userActivityStorage(activity);
    }

    login() {
        let activity = {
          name: `Log in`,
          contextParameter: [{'name': this.userService.getUserEmail()}]
        }
        this.userActivityStorage(activity);
    }

    submittedRequest() {
        let activity = {
          name: `Submitted request on contact page`,
        }
        this.userActivityStorage(activity);
    }

    landedOnPage(pageName: string){
        let activity = {
            name: 'Landed on a Page',
            contextParameter: [{ 'name': pageName }]
          }
          this.userActivityStorage(activity);
    }

    viewMoreSummary(id, name) {
        let activity = {
            name: 'View more summary',
            contextParameter: [{'id': id}, {'name': name}]
          }
          this.userActivityStorage(activity);
    }

    navigateToAssetUrl(dashboard, assetId, assetName, url) {
        let activity = {
            name: 'Asset URL navigation',
            contextParameter: [{'id': assetId}, {'name': assetName}, {'url': url}, {'dashboard': dashboard}]
        }
        this.userActivityStorage(activity);
    }

    getCurrentPage() {
        if (this.router.url?.includes('asset')) {
            return 'asset dashboard'
        } else if (this.router.url?.includes('holding')) {
            return 'holdings dashboard'
        } else if (this.router.url?.includes('series')) {
            return 'series dashboard'
        } else if (this.router.url?.includes('contact')) {
            return 'contact'
        } else if (this.router.url?.includes('document-review')){
            return 'Document Review'
        } else if (this.router.url?.includes('investor')){
            return 'investor dashboard'
        } else {
            return 'login'
        }
    }
}