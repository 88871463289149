import { Component, Input, SimpleChanges } from '@angular/core';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { DataSeries } from 'src/app/models/classes/data-series.class';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { HighchartsService } from 'src/app/services/highcharts-service.service';
import { BaseWidgetComponent } from 'src/app/shared/base-widget/base-widget.component';
import { GcmChartComponent } from 'src/app/shared/gcm-chart/gcm-chart.component';
import { InfoIconComponent } from 'src/app/shared/info-icon/info-icon.component';

@Component({
  selector: 'app-largest-position-widget',
  standalone: true,
  imports: [BaseWidgetComponent, InfoIconComponent, GcmChartComponent, TourMatMenuModule],
  templateUrl: './largest-position-widget.component.html'
})
export class LargestPositionWidgetComponent {
  @Input() widgets: Record<string, KeyValuePair<string | number, number>[]>;
  @Input() currency: string;
  largestPositionsByRemainingValue: DataSeries;
  largestPositionsByRemainingValueSum = -1;
  largestPositionsByRemainingValueChartOptions = {};
  chartStyle = { width: '99%', display: 'flex' };
  totalRemainingMarketValue: number = 0

  buildLargestPositionsChartOptions(sum) {
    const self = this;
    return {
      chart: {
        height: 365,
        type: 'funnel',
        marginBottom: 115,
        marginRight: 0
      },
      plotOptions: {
        series: {
          center: ['45%', '50%'],
          neckWidth: '7%',
          neckHeight: '0%',
          width: '35%',
          states: {
            inactive: {
              opacity: 1
            }
          },
          dataLabels: {
            formatter: function () {
              let valToShow = ((Math.abs(this.y) / Number(sum)) * 100).toFixed(1) + '%';
              return valToShow;
            }
          }
        }
      },
      legend: {
        enabled: true,
        symbolRadius: 0,
        alignColumns: false,
        layout: 'vertical',
        align: 'right',
        itemStyle: {
          fontWeight: '400',
          cursor: 'auto'
        }
      },
      tooltip: {
        formatter: function () {
          let valToShow = self.currencyFormat.transform(this.y, self.currency);
          const data = `${this.series.name} <br/>` + valToShow + ' <br/><hr class="mt-2 mb-2 mr-0 ml-0" style="background-color: #fff">'
          const footer = `<div>${this.key}</div>`
          return data + footer
        }
      },
      series: [{
        showInLegend: true,
        allowPointSelect: false,
        colors: ['#eaf1f7', '#bfd5e7', '#86b0d1', '#5c94c1', '#3e77a4']
      }]
    }
  }

  constructor(private highchartsService: HighchartsService, private currencyFormat: CurrencyFormatPipe) { }

  ngOnInit(): void {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.widgets && this.widgets) {
      this.largestPositionsByRemainingValue = this.highchartsService.buildDataSeries(this.widgets['largestRemainingValue']?.slice(0, 5), 'Remaining Market Value')
      if (this.widgets['largestRemainingValue'] && this.widgets['largestRemainingValue'].length > 0) {
        this.largestPositionsByRemainingValueSum = this.widgets['largestRemainingValue'].map(a => a.value).reduce((total, val) => total + val, 0);
        this.largestPositionsByRemainingValueChartOptions = this.buildLargestPositionsChartOptions(this.largestPositionsByRemainingValueSum);
      }
    }
  }
}
