import { CommonModule } from '@angular/common';
import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-base-widget',
  standalone: true,
  imports: [CommonModule], 
  templateUrl: './base-widget.component.html',
  styleUrls: ['./base-widget.component.scss']
})
export class BaseWidgetComponent {
  @Input() error: string = '';
  @Input() card: boolean = true;
  @Input() cardClass: string = ''; 
}
