<div tourAnchor="series.dashboard.summary">
    <div class="portfolio-summary flex flex-wrap">
        <div class="portfolio-title min-h-10 pb-0 text-md lg:text-lg" data-cy="portfolioLabel">
            {{portfolioSummary[0]?.name}} </div>
        <div class="flex ml-auto mt-1">
            <div>
                <img src="{{iconSet['icon.calendar']}}" class="mt-2 mr-1.5" />
            </div>
            <gcm-date-picker [dateOptionList]="asOfDropdownData" [(date)]="selectedAsOfDate"
                (dateChange)="reportingPeriodChange.emit($event)" [selectPanelClass]="'as-of-date-panel'"
                data-cy="asOfDateDropdown" class="as-of-date mr-3"
                [disabledOption]="isPortfolioSeriesDataUnavailable && valReportingDate == selectedAsOfDate"
                [ngClass]="{'error-text' : isPortfolioSeriesDataUnavailable && valReportingDate}"></gcm-date-picker>
        </div>
    </div>
    <div class="series-summary">
        <div class="series-title flex min-h-10 p-0 flex-wrap">
            <div class="pt-1 font-bold text-md lg:text-2xl" data-cy="seriesLabel">{{seriesSummary[0]?.name}}</div>
            <div class="text-sm p-1.5 ml-0 lg:pt-3 flex text-label-color font-normal" data-cy="asOfDateLabel">as of
                {{selectedDateObj | dateFormat : 'MM/dd/yyyy'}}
                <div *ngIf="portfolioSummary[0]?.publishValuationType === 'Audit'" class="final"
                    data-cy="auditPublished">-- Final</div>
            </div>
            <div class="snap-shot mr-3 ml-auto" *ngIf="!isPortfolioSeriesDataUnavailable">
                <button class="my-0 md:my-2.5 flex border-primary" (click)="snapShotBtnClick.emit()"
                    tourAnchor="series.dashboard.snapshot" data-cy="seriesSnapshot"><img
                        src="{{iconSet['icon.snapshot']}}" class="mr-2 mt-1" />Snapshot</button>
            </div>
        </div>
        <div>
            <img [src]="assetClassImage[1]" class="min-h-5" [matTooltip]="assetClassImage[0]"
                matTooltipClass="blue-tooltip" matTooltipPosition="right">
        </div>
        <div class="series-card mt-3 bg-white grid gap-4 grid-cols-2 md:grid-cols-6 text-sm lg:text-lg"
            data-cy="seriesCard" *ngIf="!noResultsSeries">
            <div class="info-div justify-self-center md:justify-self-start">
                <h2 class="card-value" data-cy="balanceValue">{{seriesSummary[0]?.commitment |
                    currencyFormat:portfolioSummary[0]?.currency}}</h2>
                <p class="card-label">Commitments</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start">
                <h2 class="card-value" data-cy="balanceValue">{{seriesSummary[0]?.contributions |
                    currencyFormat:portfolioSummary[0]?.currency}}
                </h2>
                <p class="card-label">Contributions</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start">
                <h2 class="card-value" data-cy="balanceValue">{{seriesSummary[0]?.distributions |
                    currencyFormat:portfolioSummary[0]?.currency}}
                </h2>
                <p class="card-label">Distributions</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start">
                <h2 class="card-value" data-cy="balanceValue">
                    {{seriesSummary[0]?.nav |
                    currencyFormat:portfolioSummary[0]?.currency}}
                </h2>
                <p class="card-label">Net Asset Value</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start ml-16 md:ml-5">
                <h2 class="card-value flex justify-center" data-cy="tvpi"
                    [ngClass]="{'pr-6': !seriesSummary[0]?.tvpiIcon}">
                    {{seriesSummary[0]?.tvpi | tvpiValueFormat}}
                    <div class="ml-2 lg:mt-1" *ngIf="seriesSummary[0]?.tvpiIcon">
                        <img data-cy="tvpiIcon" src="{{seriesSummary[0]?.tvpiIcon}}"
                            [matTooltip]="seriesSummary[0]?.tvpiTitle" matTooltipClass="blue-tooltip"
                            matTooltipPosition="right">
                    </div>
                </h2>
                <p class="card-label mr-6">Investment TVPI</p>
            </div>
            <div class="info-div justify-self-center md:justify-self-start ml-16 md:ml-5 mb-3 md:mb-0">
                <h2 class="card-value flex justify-center" data-cy="irr"
                    [ngClass]="{'pr-6': !seriesSummary[0]?.irrIcon}">
                    {{seriesSummary[0]?.irr | irrValueFormat}}
                    <div class="ml-2 lg:mt-1" *ngIf="seriesSummary[0]?.irrIcon">
                        <img data-cy="irrIcon" src="{{seriesSummary[0]?.irrIcon}}"
                            [matTooltip]="seriesSummary[0]?.irrTitle" matTooltipClass="blue-tooltip"
                            matTooltipPosition="right">
                    </div>
                </h2>
                <p class="card-label mr-6">Investment IRR</p>
            </div>
        </div>
        <div class="series-card mt-3 no-result-text bg-white" data-cy="seriesNoResult" *ngIf="noResultsSeries">
            {{highchartService.noResultsText}}</div>
    </div>
</div>