import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InvestorRepository } from './repositories/investor.repository';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { EnvironmentVariablesService } from './environment-variables.service';
import { InvestorMeta } from '../models/classes/investor-meta.class';

@Injectable({
  providedIn: 'root'
})
export class BreadcrumbDataService {
  investorsDropdownData: InvestorMeta[] = [];

  constructor(private router: Router,
    private activatedRoute: ActivatedRoute,
    private investorRepository: InvestorRepository,
    private datePipe: DateFormatPipe,
    private evService: EnvironmentVariablesService) {
    this.investorRepository.getAllEntities().subscribe((res: InvestorMeta[]) => {
      if (!res || res.length == 0) return;
      this.investorsDropdownData = res;
    })
  }

  sendHrefForDashboard(selectedInvestorId: number) {
    var selectedReportingPeriod = this.getReportingPeriodForSelectedInvestor(selectedInvestorId) ?? this.getReportingPeriodValue();
    var url = new URL(location.origin);
    url.searchParams.set('investorid',selectedInvestorId.toString());
    if(selectedReportingPeriod) url.searchParams.set('reportingdate',selectedReportingPeriod);
    return url.href;
  }

  // When user changes investor from investor dropdown from any page this will get called
  redirectToDashboard(selectedInvestorId: number) {
    var selectedReportingPeriod = this.getReportingPeriodForSelectedInvestor(selectedInvestorId) ?? this.getReportingPeriodValue();
    this.redirectToPage('/investor', { 'reportingdate': selectedReportingPeriod, investorid: selectedInvestorId });
    this.investorRepository.setActiveEntity(selectedInvestorId);
  }

  getReportingPeriodForSelectedInvestor(selectedInvestorId: number) {
    const currentReportingPeriod = this.activatedRoute.snapshot.queryParams.reportingdate;
    if (currentReportingPeriod) {
      let reportingPeriods = Array.from(new Set(this.investorsDropdownData.filter((i: InvestorMeta) => i.id === Number(selectedInvestorId)).flatMap((i: InvestorMeta) => i.reportingPeriods)));
      //do not show reporting periods prior to set hideDatesPriorDate
      reportingPeriods = reportingPeriods.filter(period => new Date(period).getTime() >= new Date(this.evService.hideDatesPriorToDate).getTime()).map(period => period.slice(0, 10))
      let isNotCurrentPeriod = (d: string) => d !== currentReportingPeriod;
      return (reportingPeriods.length > 0 && reportingPeriods?.every(isNotCurrentPeriod)) ? reportingPeriods[0] : currentReportingPeriod;
    }
    return null;
  }

  getQueryParamsForBreadcrumbs(breadcrumb) {
    var reportingPeriod = '';
    let queryParams = {};
    if (breadcrumb.route.includes('/investor')) {
      reportingPeriod = this.getReportingPeriodForSelectedInvestor(breadcrumb.id) ?? this.getReportingPeriodValue();
      queryParams['investorid'] = breadcrumb.id;
    } else {
      reportingPeriod = this.getReportingPeriodValue();
    }
    queryParams['reportingdate'] = reportingPeriod;
    return queryParams;
  }

  redirectToPage(route, params) {
    this.router.navigate([route], { queryParams: params });
  }

  getReportingPeriodValue() {
    let reportingPeriod = this.activatedRoute?.snapshot?.queryParams?.reportingdate;
    if (document.getElementById('asOfDateSel')) {
      let date = this.datePipe.transform((document.getElementById('asOfDateSel') as HTMLInputElement).value, 'yyyy-MM-dd')
      reportingPeriod = date && date !== 'N/A' ? date : reportingPeriod;
    }
    return reportingPeriod;
  }

  getAssetName(assetsDataForBreadcrumb, assetId: number) {
    let asset = assetsDataForBreadcrumb.filter(asset => asset.id == assetId)[0]
    if (asset) {
      return asset.name
    }
  }

  getDashboardBreadcrumb(queryParamsInvestorId) {
    return { 
      id: queryParamsInvestorId, 
      name: 'Dashboard', 
      route: '/investor', 
      data: null, 
      type: 'Dashboard' 
    };
  }

  getPortfolioSeriesBreadcrumbs(queryParamsInvestorId, queryParamsSeriesId, parentData) {
    return { 
      id: parentData.id, 
      name: parentData.parent.name + ': ' + parentData.name, 
      route: `/investor/${queryParamsInvestorId}/series/${queryParamsSeriesId}`, 
      type: 'Series'
    }
  }

  getHoldingsBreadcrumbs(queryParamsInvestorId, queryParamsSeriesId, queryParamsHoldingId, parentData) {
    let name = parentData.parent.name == parentData.name ? parentData.name : parentData.parent.name + ': ' + parentData.name;
    return { 
      id: parentData.id, 
      name: name, 
      route: `/investor/${queryParamsInvestorId}/series/${queryParamsSeriesId}/holding/${queryParamsHoldingId}`, 
      type: 'Holding' 
    }
  }
}