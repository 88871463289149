import { Injectable } from '@angular/core';
import { DataSeries } from '../models/classes/data-series.class';
import { CurrencyFormatPipe } from '../pipes/currency-format.pipe';
import { TooltipOptions } from 'highcharts';
import topologyJson from '@highcharts/map-collection/custom/world-continents.geo.json';

@Injectable({
  providedIn: 'root'
})
export class HighchartsService {
  continentWithMapCodeMapping = [{ continent: 'Europe', code: 'eu' }, { continent: 'North America', code: 'na' }, { continent: 'Asia', code: 'as' }, { continent: 'Other', code: 'other' }];
  colorCodesMappingWithContinent = [{ code: 'eu', color: '#53325b' }, { code: 'as', color: '#465386' }, { code: 'na', color: '#4a87af' }, { code: 'other', color: '#be661b' }, { code: 'noData', color: '#d1d3d5' }]
  noResultsText: string = 'No Results';
  tooltipOptions: TooltipOptions = {
    backgroundColor: 'rgb(18, 76, 135, 0.85)',
    borderColor: '#fff',
    borderRadius: 8,
    style: {
      color: '#fff',
      fontSize: '13px',
      fontFamily: 'Poppins, sans-serif'
    },
    useHTML: true,
    borderWidth: 0.5,
    shadow: true
  }

  constructor(private currencyFormat: CurrencyFormatPipe) {
    topologyJson.features.push({
      "type": "Feature",
      "id": "O",
      "properties": {
        "hc-key": "o",
        "name": "Others"
      },
      "geometry": {
        "type": "MultiPolygon",
        "coordinates": [[[[4200, 5500], [4200, 5501], [4201, 5501], [4201, 5500], [4200, 5500]]]]
      }
    })
  }

  getDefaultHighchartsOptions(chartType: 'bar' | 'line', datasets: DataSeries[] | DataSeries): Highcharts.Options {
    const self = this;
    var series: Highcharts.SeriesOptionsType[] = []
    if (datasets) {
      if (Array.isArray(datasets)) {
        datasets.forEach(dataSeries => {
          series.push(this.getSeriesFromDataset(chartType, dataSeries))
        })
      }
      else {
        series.push(this.getSeriesFromDataset(chartType, datasets))
      }
    }
    let sum = -1;
    if (series.length > 0) {
      sum = 0;
      series[0]['data'].map(a => a[1]).forEach(v => {
        sum += Math.abs(v);
      })
      if (sum == 0) {
        // safety check to prevent divide by 0 if somehow the whole series vals are 0
        sum = -1
      }
    }
    return {
      chart: {
        type: chartType,
        height: 200
      },
      title: {
        text: ''
      },
      xAxis: {
        type: 'category',
        title: {
          text: null
        },
        labels: {
          style: {
            fontSize: '13px'
          }
        },
        tickmarkPlacement: 'on',
        tickWidth: 1
      },
      yAxis: {
        title: {
          text: null
        },
        gridLineColor: '#000',
        gridLineWidth: 0,
        labels: {
          enabled: false
        },
        plotLines: [{
          color: 'rgb(204, 214, 235)',
          width: 1,
          value: 0
        }]
      },
      legend: {
        enabled: false
      },
      tooltip: {
        enabled: true,
        ...self.tooltipOptions
      },
      plotOptions: {
        series: {
          dataLabels: {
            enabled: true,
            formatter: function () {
              let valToShow = ((Math.abs(this.y) / Number(sum)) * 100).toFixed(1) + '%';
              return valToShow;
            },
            style: {
              fontSize: '13px'
            },
            crop: false
          }
        }, bar: {
          pointWidth: 20
        }
      },
      series: series
    }
  }

  getMapHighchartsOptions(datasets: Record<string, number>[], currency: string = 'USD'): Highcharts.Options {
    const self = this;
    return {
      chart: {
        map: topologyJson,
        height: 340
      },
      title: {
        text: ''
      },
      tooltip: {
        ...self.tooltipOptions,
        formatter: function () {
          if (this.series.data.filter(data => data.name === this.key)[0]['actualValue'] !== 0) {
            const seriesData = this.series.data.filter(data => data.name === this.key)[0];
            let regionVal = ['oc', 'af', 'sa'].includes(seriesData['key']) ? 'Others' : this.key
            let valToShow = self.currencyFormat.transform(seriesData['actualValue'], currency);
            const data = `Remaining Market Value <br/>` + valToShow + ' <br/><hr class="mt-2 mb-2 mr-0 ml-0" style="background-color: #fff">'
            const footer = `<div>${regionVal}</div>`
            return data + footer
          }
          else {
            return false;
          }
        },
        hideDelay: 100,
        outside: true,
        backgroundColor: 'rgb(18, 76, 135, 1)'
      },
      legend: {
        enabled: false
      },
      colorAxis: {
        min: 0
      },
      series: [{
        data: datasets,
        type: 'map',
        states: {
          hover: {
            enabled: false
          }
        },
        dataLabels: {
          enabled: true,
          color: '#FFFFFF',
          borderColor: '#ffffff',
          formatter: function (context) {
            return `<div id="GeographyLabel_${this.point['key'].toUpperCase()}" style="font-size: 0.95vh; text-align:center"><div style="font-weight: 700;">${this.point.value}%</div><div style="text-transform: uppercase; font-weight: 400;">${this.point['key']}</div></div>`
          },
          useHTML: true
        }
      }]
    }
  }

  getSeriesFromDataset(seriesType: 'bar' | 'line', dataSeries: DataSeries): Highcharts.SeriesOptionsType {
    var values: [string | number, number][] = [];
    if (dataSeries && dataSeries.data) {
      values = dataSeries.data.map(kvp => [kvp.key, kvp.value]);
    }
    return { name: dataSeries?.name, type: seriesType, data: values }
  }

  buildDataSeries(widget: any, name: string, errorMessage: string = this.noResultsText): DataSeries {
    if (widget && widget.length > 0) {
      return { name, data: widget, error: '' };
    }
    else {
      return {
        name,
        data: null, error: errorMessage
      };
    }
  }
}