<app-base-widget [cardClass]="'bg-white'">
    <mat-tab-group animationDuration="0" (selectedTabChange)="handleChange($event)">
        <mat-tab>
            <ng-template mat-tab-label>
                <div header class="flex">{{tabs[0]}}
                    <app-info-icon tooltipPosition="above" tooltipClass="info-icon-top-8"
                        tooltipInfo="Primary region in which underlying assets operate">
                    </app-info-icon>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <app-base-geography-widget [widgets]="widgets" [currency]="currency"></app-base-geography-widget>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div header class="flex">{{tabs[1]}}
                    <app-info-icon tooltipPosition="above" tooltipClass="info-icon-top-8"
                        tooltipInfo="GICS Sector Classification for underlying assets">
                    </app-info-icon>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <app-base-widget [card]="false" [error]="remainingValueBySectors?.error" data-cy="sectorsChart">
                    <span class="remaining-value-text">By Remaining Value</span>
                    <gcm-chart [chartData]="remainingValueBySectors" chartType="bar" [chartStyle]="chartStyle"
                        [chartOptions]="remainingValueBySectorsChartOptions"></gcm-chart>
                </app-base-widget>
            </ng-template>
        </mat-tab>
        <mat-tab>
            <ng-template mat-tab-label>
                <div header class="flex">{{tabs[2]}}
                    <app-info-icon tooltipPosition="above" tooltipClass="info-icon-top-8" 
                    tooltipInfo="Private/public status of underlying assets">
                    </app-info-icon>
                </div>
            </ng-template>
            <ng-template matTabContent>
                <app-base-widget [card]="false" [error]="remainingValueByAssetStatus?.error"
                    data-cy="assetStatusChart">
                    <span class="remaining-value-text">By Remaining Value</span>
                    <gcm-chart [chartData]="remainingValueByAssetStatus" chartType="pie" [chartStyle]="chartStyle"
                        [chartOptions]="remainingValueByAssetStatusChartOptions"></gcm-chart>
                </app-base-widget>
            </ng-template>
        </mat-tab>
    </mat-tab-group>
</app-base-widget>