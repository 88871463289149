import { Component, Input } from '@angular/core';
import { KeyValuePair } from 'src/app/models/classes/key-value-pair.class';
import { BaseGeographyWidgetComponent } from 'src/app/shared/base-geography-widget/base-geography-widget.component';
import { BaseWidgetComponent } from 'src/app/shared/base-widget/base-widget.component';
import { InfoIconComponent } from 'src/app/shared/info-icon/info-icon.component';

@Component({
  selector: 'app-geography-widget',
  standalone: true,
  imports: [BaseWidgetComponent, InfoIconComponent, BaseGeographyWidgetComponent],
  templateUrl: './geography-widget.component.html'
})
export class GeographyWidgetComponent {
  @Input() widgets: Record<string, KeyValuePair<string | number, number>[]>;
  @Input() currency: string;
  byText = "By Remaining Market Value"
}
