import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { ContentfulService } from '../services/contentful.service';
import { ContentfulConfigService } from '../services/contentful-config.service';
import { SupportRequest, SupportRequestCategory } from '../models/classes/support-request.class';
import { ApiService } from '../core/http/api.service';
import { ActivityTrackingService } from '../services/activity-tracking.service';
import { CommonModule } from '@angular/common';
import { GcmLoadingService, GcmMatDialogService, GcmNotificationService, Notification, NotificationType } from '@gcm/gcm-ui-angular';

@Component({
  selector: 'app-contact',
  standalone: true,
  imports: [CommonModule, FormsModule, ReactiveFormsModule],
  templateUrl: './contact.component.html',
  styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
  contactForm: FormGroup;
  labelTexts: any;
  iconSet: {};
  optionValues: {};

  constructor(
    private formBuilder: FormBuilder,
    private contentfulConfig: ContentfulConfigService,
    private ContentfulService: ContentfulService,
    private apiService: ApiService,
    private loadingService: GcmLoadingService,
    private notificationService: GcmNotificationService,
    private activityTracking: ActivityTrackingService,
    private dialogService: GcmMatDialogService
  ) { }

  ngOnInit(): void {
    this.ContentfulService.fetchCommonAssets('contactPage', '', this.contentfulConfig.contactPage).subscribe((data: any) => {
      this.labelTexts = this.ContentfulService.buildKeyValueLabels(data.data.landingPage.sectionsCollection.items[0].resourcesCollection.items);
      this.optionValues = data.data.landingPage.sectionsCollection.items[1].resourcesCollection.items;
    });
    this.ContentfulService.getIconSetValue().subscribe(data => {
      if (data) this.iconSet = data;
    });

    this.contactForm = this.formBuilder.group(
      {
        subject: ['', [Validators.required, Validators.maxLength(256)]],
        category: ['', Validators.required],
        message: ['', [Validators.required, Validators.maxLength(1000)]],
      }
    )
    this.activityTracking.landedOnPage('Contact');
  }

  get form() {
    return this.contactForm.controls;
  }

  submitContactForm() {
    if (this.contactForm.valid) {
      this.activityTracking.submittedRequest();
      let request = new SupportRequest(this.contactForm.value);
      request.category = this.form.category.value.trim() === "Data Inquiries" ? SupportRequestCategory.DataInquiry : (this.form.category.value.trim() === "Technical Support" ? SupportRequestCategory.TechnicalSupport : SupportRequestCategory.Unknown);
      request.raygunId = localStorage.getItem('raygun4js-sid');
      this.loadingService.setLoading(true);
      this.apiService.contactSupport(request).subscribe({
        next: () => {
          this.contactForm.reset({ category: '' });
          this.loadingService.setLoading(false);
          this.notificationService.notify(new Notification("Your request was successfully submitted. The GCM team will respond soon", "Contact", NotificationType.success));
        },
        error: () => {
          this.loadingService.setLoading(false);
          this.notificationService.notify(new Notification(`Your request was not submitted. Please try again or contact the GCM team directly at <a href="mailto:grosvenor.reporting@gcmlp.com">grosvenor.reporting@gcmlp.com</a>`, "Contact", NotificationType.error));
        }
      });
    }
    else {
      this.contactForm.markAllAsTouched();
    }
  }

  canDeactivate() {
    if (this.contactForm.value.subject || this.contactForm.value.category || this.contactForm.value.message) {
      return this.dialogService.showConfirmationDialog(this.labelTexts['contact.wb.msg'], this.labelTexts['contact.wb.submsg'], this.labelTexts['contact.wb.leave.pagebtn'], this.labelTexts['contact.wb.continuebtn']);
    }
    return true;
  }
} 
