<div data-cy="investorDashboard" class="main-content mt-16">
    <div *ngIf="iconSet" class="pl-3 pr-1.5 lg:pl-[1rem] pb-3 pt-2">
        <div *ngIf="errorMessage || showNoAccessError" class="ml-3">
            <div *ngIf="showNoAccessError; else showErrorMessage">
                <app-no-access-error (updateInvestor)="updateInvestor($event)"></app-no-access-error>
            </div>
            <ng-template #showErrorMessage>
                <div class="row mr-0">
                    <div class="col-12">
                        <div class="mt-3 dashboard-error">
                            <div>
                                <h3 class="text-center">{{errorMessage}}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-template>
        </div>
        <ng-container *ngIf="!errorMessage && !showNoAccessError">
            <div class="container">
                <div class="heading-row">
                    <div class="grid grid-cols-2 heading">
                        <h1 class="heading__title text-5xl md:text-6xl">Dashboard</h1>
                        <div class="text-right asOfDate flex flex-row ml-auto">
                            <span class="asOfDate__text self-center pr-2 mb-0.5">as of</span>
                            <gcm-date-picker [dateOptionList]="asOfDropdownData" [(date)]="selectedAsOfDate" (dateChange)="updateAsOfDate($event)"
                            [selectPanelClass]="'as-of-date-panel'" data-cy="asOfDateDropdown" tourAnchor="investor.dashboard.reportingPeriod" class="as-of-date"></gcm-date-picker>
                        </div>
                    </div>
                </div>
                <ng-container *ngIf="investorPortfolioData.length > 0">
                    <!-- Investment summary div -->
                    <ng-container *ngIf="investorSummary?.currency">
                        <app-investor-summary [iconSet]="iconSet" [investorSummary]="investorSummary"
                            [showBanner]="showBanner"></app-investor-summary>
                    </ng-container>
                    <!-- Portfolio series div -->
                    <app-portfolio-summary [investorPortfolioData]="investorPortfolioData" [iconSet]="iconSet"
                        [activeInvestorMetadata]="activeInvestorMetadata"
                        [selectedAsOfDate]="selectedAsOfDate | dateFormat  : 'YYYY-MM-dd'"></app-portfolio-summary>
                </ng-container>
            </div>
        </ng-container>
    </div>
</div>