<div class="session-wrap">
  <div class="session-container">
    <div class="session-pod w-[90vw] h-[40vh] lg:w-[950px] md:h-[520px]">
      <div class="container h-full">
        <div class="row grid grid-cols-1 md:grid-cols-3 h-full">
          <div class="hidden md:flex md:col-span-1 image">
            <img class="max-w-[85%] lg:max-w-full" src="{{ sessionImageUrl }}" alt="session" />
          </div>
          <div class="md:col-span-2 session-content">
            <div class="first-header text-3xl md:text-5xl lg:text-7xl">{{ headingText1 }} </div>
            <div class="second-header text-2xl md:text-4xl lg:text-6xl">{{timeoutService.sessionTimeLeft}} {{
              headingText2 }}</div>
            <p class="text-lg md:text-2xl" [innerHTML]="description | safeHtml"></p>
            <div class="grid grid-cols-2 pt-4 gap-4">
              <button class="btn btn-outline" (click)="logoutClick();">{{logOutButtonText}}</button>
              <button class="btn btn-primary"
                (click)="timeoutService.sessionContinueClick();">{{continueButtonText}}</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-backdrop fade show"></div>
</div>