<li class="mr-3 dropdown_li">
    <div class="text-right pl-2 lg:pl-0 lg:flex" *ngIf="showInvestorsDropList && iconSet">
        <form class="filter-form mt-1" *ngIf="!externalUser">
            <div class="flex mr-2">
                <mat-form-field class="mt-1 filter-form-field">
                    <mat-icon fontIcon="filter_alt" class="mr-1"></mat-icon>
                    <input type="text" placeholder="Filter by portfolio" matInput [formControl]="searchInput"
                        [matAutocomplete]="auto">
                    <mat-icon *ngIf="searchInput.value !== ''" aria-label="close icon" class="pt-1.5 pointer close-icon"
                        fontIcon="close_small" color="red" title="Clear Filter" (click)="resetFilter()"></mat-icon>
                    <mat-autocomplete #auto="matAutocomplete" [hideSingleSelectionIndicator]="true"
                        [displayWith]="displayFn" panelWidth="40%" (optionSelected)="filterSelectedPortfolio()">
                        <mat-option *ngIf="selectAllOptionInFilter.name !== ''" [value]="selectAllOptionInFilter">Any
                            investors with: {{selectAllOptionInFilter.name}}</mat-option>
                        <mat-option *ngIf="noMatchFilterError" [disabled]="true">No matching investors with a portfolio
                            name:
                            {{searchInput.value}}</mat-option>
                        <mat-option *ngFor="let option of filteredOptions | async" [value]="option">
                            Investors with: {{option.name}}
                        </mat-option>
                    </mat-autocomplete>
                </mat-form-field>
            </div>
        </form>
        <div class="investorIds mt-1.5">
            <img src="{{iconSet['icon.investor.dropdown']}}" alt="investor-dropdown"
                class="investorIds__icon mt-4 lg:mt-0" />
            <app-auto-adjusting-dropdown [selected]="investorName" [dataForBreadcrumbs]="filteredInvestorDropdownData"
                (goToSelected)="selectActiveInvestor($event)" (sendWidth)="getWidth($event)"
                [queryParams]="selectedInvestorId" [updateWidth]="updateWidth" [valueProperty]="'id'"
                [nameProperty]="'name'" [outerdivClass]="'investor'" [optionClass]="'font-bold'"
                [optionClassProperty]="'isVerified'" [selectClass]="'lg:whitespace-nowrap'"
                [type]="'Investors'"></app-auto-adjusting-dropdown>
        </div>
    </div>
</li>