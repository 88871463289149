import { Component, Input, OnInit, SimpleChanges } from '@angular/core';
import { MatTabChangeEvent, MatTabsModule } from '@angular/material/tabs';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';
import { DataSeries } from 'src/app/models/classes/data-series.class';
import { CurrencyFormatPipe } from 'src/app/pipes/currency-format.pipe';
import { ActivityTrackingService } from 'src/app/services/activity-tracking.service';
import { HighchartsService } from 'src/app/services/highcharts-service.service';
import { UserService } from 'src/app/services/user.service';
import { BaseWidgetComponent } from 'src/app/shared/base-widget/base-widget.component';
import { GcmChartComponent } from 'src/app/shared/gcm-chart/gcm-chart.component';

@Component({
  selector: 'app-key-performance-widget',
  standalone: true,
  imports: [MatTabsModule, BaseWidgetComponent, GcmChartComponent, TourMatMenuModule],
  templateUrl: './key-performance-widget.component.html',
  styleUrls: ['./key-performance-widget.component.scss']
})
export class KeyPerformanceWidgetComponent implements OnInit {
  @Input() widgets;
  @Input() currency: string;
  keyPerformanceDataForQuarterTop: DataSeries;
  keyPerformanceDataForQuarterSumTop = -1;
  keyPerformanceDataForQuarterBottom: DataSeries;
  keyPerformanceDataForQuarterSumBottom = -1;
  keyPerformanceQuarterChartOptionsTop = {};
  keyPerformanceQuarterChartOptionsBottom = {};
  kdpQuarterChartStyle: Record<string, string> = {};
  keyPerformanceDataSinceInceptionTop: DataSeries;
  keyPerformanceDataSinceInceptionSumTop = -1;
  keyPerformanceSinceInceptionChartOptionsTop = {};
  keyPerformanceDataSinceInceptionBottom: DataSeries;
  keyPerformanceDataSinceInceptionSumBottom = -1;
  keyPerformanceSinceInceptionChartOptionsBottom = {};
  kdpInceptionChartStyle: Record<string, string> = {};
  tabs = ['', 'Top Contributors', 'Bottom Contributors']

  buildKeyPerformanceChartOptions(showingChartForInception, top) {
    const self = this;
    return {
      chart: {
        height: 360,
        marginRight: 0
      },
      plotOptions: {
        bar: {
          dataLabels: {
            formatter: function () {
              return self.currencyFormat.transform(this.y, self.currency,'Thousands', true, true, 2, true);
            }
          },
          enableMouseTracking: false
        }
      },
      tooltip: {
        enabled: false
      },
      series: [{
        custom: {
          showingChartForInception,
          top
        },
        color: '#4a87af',
        negativeColor: "#d39a47"
      }]
    }
  }

  constructor(private highChartsService: HighchartsService, private currencyFormat: CurrencyFormatPipe, private userService: UserService, private activityTracking: ActivityTrackingService) { }

  ngOnInit(): void {
    this.kdpQuarterChartStyle = this.kdpInceptionChartStyle = { width: '99%', display: 'flex' };
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.widgets && this.widgets) {
      const topContributorsError = 'No Top Contributors';
      const bottomContributorsError = 'No Bottom Contributors';
      const noDataError = 'No Data to Report';
      this.keyPerformanceDataForQuarterTop = this.highChartsService.buildDataSeries(this.widgets['performanceQtdTopFive'], 'Market Value Change', this.widgets['performanceQtdBottomFive'].length == 0 ? noDataError : topContributorsError)
      this.keyPerformanceDataForQuarterBottom = this.highChartsService.buildDataSeries(this.widgets['performanceQtdBottomFive'], 'Market Value Change', this.widgets['performanceQtdTopFive'].length == 0 ? noDataError : bottomContributorsError)
      this.keyPerformanceDataSinceInceptionTop = this.highChartsService.buildDataSeries(this.widgets['performanceItdTopFive'], 'Market Value Change', this.widgets['performanceItdBottomFive'].length == 0 ? noDataError : topContributorsError)
      this.keyPerformanceDataSinceInceptionBottom = this.highChartsService.buildDataSeries(this.widgets['performanceItdBottomFive'], 'Market Value Change', this.widgets['performanceItdTopFive'].length == 0 ? noDataError : bottomContributorsError)
      if (this.widgets['performanceQtdTopFive'].length > 0) { this.buildTopQuarter(); }
      if (this.widgets['performanceQtdBottomFive'].length > 0) { this.buildBottomQuarter(); }
      if (this.widgets['performanceItdTopFive'].length > 0) { this.buildTopInception(); }
      if (this.widgets['performanceItdBottomFive'].length > 0) { this.buildBottomInception(); }
    }
  }

  handleChange(event: MatTabChangeEvent) {
    this.activityTracking.widgetTabChange('Key performance widget', this.tabs[event.index])
    this.userService.raygunTrackMatTabChange(event);
  }

  buildTopQuarter() {
    this.keyPerformanceDataForQuarterSumTop = this.keyPerformanceDataForQuarterTop.data.map(a => a.value).reduce((total, val) => total + Math.abs(val), 0);
    this.keyPerformanceQuarterChartOptionsTop = this.buildKeyPerformanceChartOptions(false, true);
  }

  buildBottomQuarter() {
    this.keyPerformanceDataForQuarterSumBottom = this.keyPerformanceDataForQuarterBottom.data.map(a => a.value).reduce((total, val) => total + Math.abs(val), 0);
    this.keyPerformanceQuarterChartOptionsBottom = this.buildKeyPerformanceChartOptions(false, false);
  }

  buildTopInception() {
    this.keyPerformanceDataSinceInceptionSumTop = this.keyPerformanceDataSinceInceptionTop.data.map(a => a.value).reduce((total, val) => total + Math.abs(val), 0);
    this.keyPerformanceSinceInceptionChartOptionsTop = this.buildKeyPerformanceChartOptions(true, true);
  }

  buildBottomInception() {
    this.keyPerformanceDataSinceInceptionSumBottom = this.keyPerformanceDataSinceInceptionBottom.data.map(a => a.value).reduce((total, val) => total + Math.abs(val), 0);
    this.keyPerformanceSinceInceptionChartOptionsBottom = this.buildKeyPerformanceChartOptions(true, false);
  }
}

