import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { LoggingService } from './logging.service';
import { LocationStrategy, PathLocationStrategy } from '@angular/common';
import { GcmNotificationService } from '@gcm/gcm-ui-angular';
import { EnvironmentVariablesService } from './environment-variables.service';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {

    // Error handling is important and needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private injector: Injector, private notificationservice: GcmNotificationService, private environmentService: EnvironmentVariablesService) { }
    handleError(error: any) {
        const loggingService = this.injector.get(LoggingService);
        let err;
        const location = this.injector.get(LocationStrategy);
        const url = location instanceof PathLocationStrategy ? location.path() : '';
        err = error;
        if (this.environmentService.notificationConfig['showNotification']) {
            this.notificationservice.notifyError(err);
        }
        // Always log errors
        loggingService.log(url, err);
    }
}