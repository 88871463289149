import { CommonModule } from '@angular/common';
import { Component, ElementRef, Input, OnChanges, QueryList, SimpleChanges, ViewChildren } from '@angular/core';

@Component({
  selector: 'app-image-carousel',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './image-carousel.component.html',
  styleUrls: ['./image-carousel.component.scss']
})
export class ImageCarouselComponent implements OnChanges {
  @Input() slidingImgs = new Array();
  currentIndex = 0;
  positions: number[] = [];
  currentTransform = 0;
  @ViewChildren('imageElement') imageElements!: QueryList<ElementRef<HTMLImageElement>>;

  constructor(
  ) {
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.slidingImgs) {
      this.resetSlider();
    }
  }

  resetSlider() {
    this.currentIndex = 0; // Reset to the first image
    this.positions = [];
    this.currentTransform = 0;

    // Allow Angular to update the DOM, then recalculate positions
    setTimeout(() => {
      this.calculatePositions();
    });
  }

  calculatePositions() {
    this.positions = [];
    let cumulativeOffset = 0;

    this.imageElements.forEach((image) => {
      const imgWidth = image.nativeElement.offsetWidth; // Dynamically get the width of each image
      this.positions.push(cumulativeOffset);
      cumulativeOffset += imgWidth;
    });

    this.updateTransform(); // Update transform after recalculation
  }

  nextSlide() {
    if (this.currentIndex < this.slidingImgs.length - 1) {
      this.currentIndex++;
    } else {
      this.currentIndex = 0; // Loop to the beginning
    }
    this.updateTransform();
  }

  prevSlide() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    } else {
      this.currentIndex = this.slidingImgs.length - 1; // Loop to the end
    }
    this.updateTransform();
  }

  updateTransform() {
    this.currentTransform = this.positions[this.currentIndex] || 0;
  }

  goToSlide(index: number) {
    this.currentIndex = index;
    this.updateTransform();
  }
}
