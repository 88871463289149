import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
    name: 'currencySymbol',
    standalone: true
})
export class CurrencySymbolPipe implements PipeTransform {

    transform(
        code: string,
        addParenthesis: boolean = false,
        locale?: string
    ): any {
        if(!code) return '';
        // Use Intl.NumberFormat to extract the currency symbol
        const parts = new Intl.NumberFormat(locale, { style: 'currency', currency: code }).formatToParts();
        const currencySymbol = parts.find(part => part.type === 'currency')?.value;

        // If addParenthesis is true, wrap the symbol in parentheses
        if (addParenthesis && currencySymbol) {
            return `(${currencySymbol})`;
        }

        return currencySymbol;
    }
}