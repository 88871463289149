<div class="slider-container">
    <div class="slider" [style.transform]="'translateX(-' + currentTransform + 'px)'">
        <img #imageElement *ngFor="let image of slidingImgs" [src]="image.src" alt="Slide" />
    </div>
    <button (click)="prevSlide()" class="nav-button prev" *ngIf="slidingImgs.length > 1"> <span>❮</span>
    </button>
    <button (click)="nextSlide()" class="nav-button next" *ngIf="slidingImgs.length > 1"> <span>❯</span>
    </button>

    <!-- Dots Container -->
    <div class="dots" *ngIf="slidingImgs.length > 1">
        <span *ngFor="let image of slidingImgs; let i = index" [class.active]="currentIndex === i"
            (click)="goToSlide(i)">
        </span>
    </div>
</div>