<link href="https://global.oktacdn.com/okta-signin-widget/7.25.1/css/okta-sign-in.min.css" type="text/css"
  rel="stylesheet" />
<div class="h-screen">
  <div class="login-wrap lg:grid lg:grid-cols-[45%_55%] h-full">
    <div class="hidden lg:flex login-wrap__left justify-content-center align-items-center h-full"
      style="background: url('{{backgroundImageUrl}}') no-repeat center;">
      <div class="login-wrap__logo">
        <img src="{{siteLogo}}" title="GCM">
      </div>
      <div class="login-wrap__text">
        <div class="heading">{{pageHeading}}</div>
        <div [innerHTML]="pageContent | safeHtml"></div>
      </div>
      <div class="login-wrap__copyright">
        &copy; {{copyrightText}}
      </div>
    </div>
    <div class="login-wrap__right h-full lg:my-0 lg:mx-auto lg:w-[450px]">
      <div class="flex lg:hidden gcm-logo">
        <img src="{{gcmLogo}}" title="GCM" alt="Gcm-logo" />
      </div>
      <div class="login-wrap__right__container h-full lg:w-[450px]">
        <div class="lg:m-0 lg:pl-8 login-wrap__right__logo">
          <img src="{{clientScopeLogo}}" title="Client Scope" class="lg:h-[100px]" />
        </div>
        <div id="okta-login-container"></div>
      </div>
      <div class="login-wrap__terms flex lg:block ">
        <a href="javaScript:;" (click)="open()" data-cy="privacyButton">{{privacyPolicyLinkText}}</a>
      </div>
    </div>
  </div>
</div>