import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router, RouterModule } from '@angular/router';
import { BreadcrumbDataService } from '../services/breadcrumb-data.service';
import { ContentfulService } from '../services/contentful.service';
import { SearchEngineService } from '../services/search-engine.service';
import { ApiService } from 'src/app/core/http/api.service';
import { PortfolioSeriesDashboard } from '../models/classes/portfolio-series-dashboard.class';
import { BaseEntityDetails } from '../models/classes/entity-details.class';
import { PortfolioSeries } from '../models/classes/portfolio-series.class';
import { InvestmentTypeHierarchy } from '../models/classes/investment-type-hierarchy.class';
import { KeyValuePair } from '../models/classes/key-value-pair.class';
import { SnapshotService } from '../services/snapshot.service';
import { LoggingService } from '../services/logging.service';
import { PortfolioSeriesMeta } from '../models/classes/portfolio-series-meta.class';
import { RelatedEntity } from '../models/classes/related-entity.class';
import { UserService } from '../services/user.service';
import { NgTourService } from '../services/ng-tour-ui.service';
import { NgTourStepsService } from '../services/ng-tour-steps.service';
import { EnvironmentVariablesService } from '../services/environment-variables.service';
import { MetadataService } from '../services/metadata.service';
import { InvestorRepository } from '../services/repositories/investor.repository';
import { ActivityTrackingService } from '../services/activity-tracking.service';
import { CommonModule } from '@angular/common';
import { NoAccessErrorComponent } from '../shared/no-access-error/no-access-error.component';
import { MatIconModule } from '@angular/material/icon';
import { AutoAdjustingDropdownComponent } from '../auto-adjusting-dropdown/auto-adjusting-dropdown.component';
import { FormsModule } from '@angular/forms';
import { ErrorCardComponent } from '../shared/error-card/error-card.component';
import { SeriesDashboardSummaryComponent } from './series-dashboard-summary/series-dashboard-summary.component';
import { CommitmentValueWidgetComponent } from './commitment-value-widget/commitment-value-widget.component';
import { RemainingValueWidgetComponent } from './remaining-value-widget/remaining-value-widget.component';
import { KeyPerformanceWidgetComponent } from './key-performance-widget/key-performance-widget.component';
import { SeriesHoldingsTableComponent } from './series-holdings-table/series-holdings-table.component';
import { SeriesQRollTableComponent } from './series-qroll-table/series-qroll-table.component';
import { DateFormatPipe } from '../pipes/date-format.pipe';
import { GcmLoadingService } from '@gcm/gcm-ui-angular';
import { TourMatMenuModule } from 'ngx-ui-tour-md-menu';

@Component({
  selector: 'app-series-dashboard',
  standalone: true,
  imports: [
    CommonModule,
    NoAccessErrorComponent,
    RouterModule,
    MatIconModule,
    AutoAdjustingDropdownComponent,
    FormsModule,
    ErrorCardComponent,
    SeriesDashboardSummaryComponent,
    CommitmentValueWidgetComponent,
    RemainingValueWidgetComponent,
    KeyPerformanceWidgetComponent,
    SeriesHoldingsTableComponent,
    SeriesQRollTableComponent,
    DateFormatPipe,
    TourMatMenuModule
  ],
  templateUrl: './series-dashboard.component.html',
  styleUrls: ['./series-dashboard.component.scss']
})
export class SeriesDashboardComponent implements OnInit {
  iconSet: {};
  valReportingDate: string = '';
  queryParamsSeriesId: number;
  queryParamsInvestorId: number;
  portfolioDataForBreadcrumb: RelatedEntity[] = [];
  breadcrumbs: any[] = [];
  asOfDropdownData: string[] = [];
  selectedAsOfDate: string = '';
  portfolioSummary: BaseEntityDetails[] = [];
  seriesSummary: PortfolioSeries[] = [];
  hasErrors: boolean = false;
  widgets: Record<string, KeyValuePair<string | number, number>[]>;
  dashboardData: PortfolioSeriesDashboard = new PortfolioSeriesDashboard();
  investmentTypes: InvestmentTypeHierarchy[] = [];
  params = {};
  queryParams = {};
  summary: {};
  portfolioName: string = '';
  seriesName: string = '';
  seriesNameForBreadcrumb: string = '';
  errorMessage: string = '';
  firstTimeLoadingPortfolios: boolean = true;
  showNoAccessError: boolean = false;
  currency: string = 'USD';
  isPortfolioSeriesDataUnavailable: boolean = false;
  metadata: PortfolioSeriesMeta;

  constructor(
    private seoService: SearchEngineService,
    private router: Router,
    private contentfulService: ContentfulService,
    private activatedRoute: ActivatedRoute,
    public breadcrumbService: BreadcrumbDataService,
    private apiService: ApiService,
    public snapService: SnapshotService,
    private loadingService: GcmLoadingService,
    private loggingService: LoggingService,
    private userService: UserService,
    private ngTourSteps: NgTourStepsService,
    private ngTourUi: NgTourService,
    private evService: EnvironmentVariablesService,
    private metadataService: MetadataService,
    private investorRepository: InvestorRepository,
    private activityTracking: ActivityTrackingService,
    private dateFormat: DateFormatPipe
  ) {
    this.ngTourSteps.getContentfulDataFetchedValue().subscribe((data: boolean) => {
      if (data) this.ngTourUi.setTourSteps(ngTourSteps.seriesDashboardSteps);
    })
  }

  ngOnInit(): void {
    this.showNoAccessError = false;
    this.seoService.setCanonicalURL();
    this.params = this.userService.params = this.activatedRoute.snapshot.params;
    this.queryParams = this.userService.queryParams = this.activatedRoute.snapshot.queryParams;
    this.queryParamsInvestorId = this.params['investorId'] ?? '';
    this.queryParamsSeriesId = this.params['seriesId'] ?? '';
    this.valReportingDate = this.queryParams['reportingdate'];
    //// Get contentFul common Assets ////
    this.getCommonAssets();
    this.getBreadcrumbDropdownData(this.queryParamsInvestorId, this.queryParamsSeriesId);
    if (this.userService.isExternalUser()) {
      this.checkExternalUserAccess(this.queryParamsInvestorId)
    } else {
      this.getPortfolioSeriesDashboardDetails();
    }
  }

  checkExternalUserAccess(queryParamsinvestorid: any) {
    const assignedInvestorIds = this.userService.getInvestorIds();
    if (!assignedInvestorIds.includes(parseInt(queryParamsinvestorid))) {
      this.hasErrors = true;
      this.showNoAccessError = true;
      this.loadingService.setLoading(false);
      return;
    } else {
      this.hasErrors = false;
      this.getPortfolioSeriesDashboardDetails();
    }
  }

  getPortfolioSeriesDashboardDetails() {
    this.loadingService.setLoading(true);
    this.fetchDashboardDataFromApi(this.queryParamsSeriesId, this.valReportingDate).then((data: PortfolioSeriesDashboard) => {
      this.assignDataToAllWidgets(data);
      if (!this.valReportingDate) {
        this.valReportingDate = data?.summary?.reportingPeriod?.slice(0, 10);
        this.selectedAsOfDate = this.valReportingDate;
        this.router.navigate([], {
          relativeTo: this.activatedRoute,
          queryParams: {
            reportingdate: this.valReportingDate
          },
          queryParamsHandling: 'merge'
        });
      }
      this.loadingService.setLoading(false);
    }, err => {
      this.portfolioSummary = this.seriesSummary = [];
    });
  }

  assignDataToAllWidgets(data) {
    this.dashboardData = data;
    if (data.investmentTypes) {
      this.investmentTypes = data.investmentTypes
    }
    if (data.widgets) {
      this.widgets = data.widgets
    }
    if (data.summary) {
      this.summary = data.summary
    }
    if (this.dashboardData.summary?.commitment) {
      this.isPortfolioSeriesDataUnavailable = false;
    } else {
      this.showErrorCard();
    }
    this.portfolioAndSeriesSummary();
  }

  showErrorCard() {
    this.isPortfolioSeriesDataUnavailable = true;
    this.valReportingDate = this.dateFormat.transform(this.valReportingDate, "MM/dd/YYYY")
    this.asOfDropdownData.push(this.valReportingDate);
    this.selectedAsOfDate = this.valReportingDate;
  }

  portfolioAndSeriesSummary() {
    this.portfolioSummary = [this.dashboardData.displayPortfolioDetail];
    this.currency = this.dashboardData?.displayPortfolioDetail?.currency
    this.portfolioName = this.portfolioSummary[0]?.name;
    this.seriesSummary = [this.dashboardData?.summary];
    this.seriesName = this.seriesSummary[0]?.name;
  }

  getCommonAssets() {
    this.contentfulService.getIconSetValue().subscribe(data => {
      if (data) this.iconSet = data;
    });
  }

  getBreadcrumbDropdownData(InvestorId: number, seriesId: number) {
    this.breadcrumbs = [{ id: InvestorId, name: 'Dashboard', route: '/investor' }];
    // getting reporting periods and breadcrumb data from new API endpoint
    this.metadataService.fetchSeriesDashboardMetaData(seriesId, InvestorId)
      .then((data: PortfolioSeriesMeta) => {
        this.metadata = data;
        this.assignDataForBreadcrumbAndDropdown(data);
      }).catch(err => {
        this.loggingService.log("----- Portfolio Series Dashboard Metadata API Exception -----", err);
        this.loadingService.setLoading(false);
      });
  }

  assignDataForBreadcrumbAndDropdown(data: PortfolioSeriesMeta) {
    this.asOfDropdownData = this.getAsOfDropdownData(data);
    this.valReportingDate = this.dateFormat.transform(this.valReportingDate, "MM/dd/YYYY");
    if (this.valReportingDate && this.asOfDropdownData.filter(date => date === this.valReportingDate).length > 0) {
      this.selectedAsOfDate = this.valReportingDate;
    }
    if (this.firstTimeLoadingPortfolios) {
      this.portfolioDataForBreadcrumb = data.relatedEntities;
      this.firstTimeLoadingPortfolios = false;
    }
    this.getSeriesName();
  }

  getAsOfDropdownData(data: PortfolioSeriesMeta) {
    let asOfDropdownData = [];
    data.reportingPeriods.forEach(date => {
      if (new Date(date).getTime() >= new Date(this.evService.hideDatesPriorToDate).getTime()) {
        asOfDropdownData.push(this.dateFormat.transform(date, "MM/dd/YYYY"))
      }
    })
    return asOfDropdownData
  }

  fetchDashboardDataFromApi(seriesId, reportingDate) {
    return new Promise((resolve, reject) => {
      this.apiService.getPortfolioSeriesDashboardDetails(seriesId, reportingDate).subscribe({
        next: (data) => {
          resolve(data);
        },
        error: (err) => {
          this.hasErrors = true;
          this.errorMessage = err?.error?.errors[0]?.message ?? 'Something went wrong, Please try again!';
          reject(err);
        }
      });
    })
  }

  updateAsOfDate(date) {
    var reportingDate = this.dateFormat.transform(date, 'YYYY-MM-dd')
    this.activityTracking.toggledAsOfDate(date)
    this.loadingService.setLoading(true);
    this.fetchDashboardDataFromApi(this.queryParamsSeriesId, reportingDate).then((data: PortfolioSeriesDashboard) => {
      if (this.isPortfolioSeriesDataUnavailable) {
        this.metadataService.fetchSeriesDashboardMetaData(this.queryParamsSeriesId, this.queryParamsInvestorId)
          .then((data: PortfolioSeriesMeta) => {
            this.asOfDropdownData = this.getAsOfDropdownData(data);
          }).catch(err => {
            this.loggingService.log("----- Portfolio Series Dashboard Metadata API Exception -----", err);
          });
      }
      this.valReportingDate = reportingDate;
      this.assignDataToAllWidgets(data);
      this.router.navigate(
        [], {
        relativeTo: this.activatedRoute,
        queryParams: { reportingdate: reportingDate },
        queryParamsHandling: 'merge'
      });
      this.loadingService.setLoading(false);
    });
  }

  goToSelectedSeries(seriesId) {
    this.loadingService.setLoading(true);
    // getting reporting periods and breadcrumb data from new API endpoint
    this.metadataService.fetchSeriesDashboardMetaData(seriesId, this.params['investorId'])
      .then((metadata: PortfolioSeriesMeta) => {
        this.assignDataForBreadcrumbAndDropdown(metadata);
        this.fetchDashboardDataFromApi(seriesId, this.selectedAsOfDate).then((data: PortfolioSeriesDashboard) => {
          this.assignDataToAllWidgets(data);
          let route = `/investor/${this.params['investorId']}/series/${seriesId}`;
          this.queryParamsSeriesId = seriesId;
          this.router.navigate([route], {
            relativeTo: this.activatedRoute,
            queryParams: {
              reportingdate: this.dateFormat.transform(this.selectedAsOfDate, 'YYYY-MM-dd')
            }
          });
          this.loadingService.setLoading(false);
        });
      }).catch(err => {
        this.loggingService.log("----- Portfolio Series Dashboard Metadata API Exception -----", err);
        this.loadingService.setLoading(false);
      });
  }

  getSeriesName() {
    let selectedSeries: any = {}
    this.portfolioDataForBreadcrumb.map(portfolio => {
      selectedSeries = portfolio.subEntities.filter(portfolioSeries => portfolioSeries.displayId == Number(this.queryParamsSeriesId))[0];
      if (selectedSeries) {
        this.seriesNameForBreadcrumb = selectedSeries.displayName;
      }
    });
  }

  getSnapshot() {
    this.activityTracking.downloadSnapshot(this.queryParamsSeriesId, this.seriesName)
    let fileName = 'SeriesSnapshot_' + this.portfolioName.replace(/\s/g, "_") + '_' + this.seriesName.replace(/\s/g, "_") + '_' + this.valReportingDate + '.pdf'
    this.snapService.getSnapshot(this.queryParamsSeriesId, this.valReportingDate, fileName);
  }

  breadcrumbClick(breadcrumb) {
    this.activityTracking.navigateToDashboard('Investor', breadcrumb, 'series dashboard breadcrumbs');
    if (breadcrumb.route.includes('/investor')) {
      this.investorRepository.setActiveEntity(breadcrumb.id);
    }
  }
}
