<app-base-widget [error]="sectorByRemainingMarketValue?.error" data-cy="sectorsWidget">
    <div header class="card-header flex border-b border-light-grey p-3"> Sector
        <div>
            <app-info-icon tooltipPosition="above" tooltipInfo="GICS Sector Classifications for underlying assets"
                data-cy="sectorsTooltip" tooltipClass="mt-[3px]">
            </app-info-icon>
        </div>
    </div>
    <span class="remaining-value-text remaining-value-text" data-cy="byText">By Remaining Market Value</span>
    <gcm-chart [chartData]="sectorByRemainingMarketValue" [chartStyle]="chartStyle" chartType="bar"
        [chartOptions]="sectorByRemainingMarketValueChartOptions"></gcm-chart>
</app-base-widget>