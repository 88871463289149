<mat-sidenav-container class="bg-background-color h-full w-full" autosize>
    <mat-sidenav mode="side" opened fixedInViewport="true" fixedTopGap="60" class="gcm-sidenav"
        [ngClass]="opened ? 'opened' : 'collapsed'">
        <div *ngIf="opened" class="my-2">
            <app-investor-dropdown class="lg:hidden" [updateWidth]="showMobileSideNav"></app-investor-dropdown>
        </div>
        <gcm-sidenav [(opened)]="opened" [navItems]="navigationItems" [defaultActiveOptions]="routerLinkActiveOptions" [showHorizontalDivider]="false"></gcm-sidenav>
    </mat-sidenav>
    <mat-sidenav-content class="gcm-sidenav-content bg-background-color-primary bg-opacity-75">
        <ng-template #defaultBodyTemplate><router-outlet></router-outlet></ng-template>
        <ng-container *ngTemplateOutlet="defaultBodyTemplate"></ng-container>
    </mat-sidenav-content>
</mat-sidenav-container>