<div class="row mt-4 mx-4 lg:ml-0" *ngIf="iconSet && showWidget">
    <div class="widget-card card mx-0 bg-white" data-cy="financialWidget" *ngIf="financialData">
        <div class="header-row flex">
            <h4>Financial Information</h4>
            <span data-cy="asOfDate">as of {{selectedAsOfDate | dateFormat : 'MM/dd/yyyy'}}</span>
        </div>
        <ng-container *ngIf="assetType === 'Infrastructure' || assetType === 'Portfolio Company'">
            <div class="row grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-6 lg:gap-0 inner-row px-5 pt-5" data-cy="portfolioAndInfrastructureContainer">
                <div *ngIf="assetType === 'Portfolio Company'">
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">          
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.market.cap']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="balanceValue">{{financialData.marketCap | currencyFormat:'USD':'Billions'}}
                        </div>
                        <div class="title text-right text-md md:text-lg">Market Cap</div>
                    </div>
                </div>
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.enterprise.value']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="balanceValue">{{financialData.value | currencyFormat:'USD':'Billions'}}
                        </div>
                        <div class="title text-right text-md md:text-lg">Enterprise Value</div>
                    </div>
                </div>
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.revenue']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="balanceValue">{{financialData.revenue | currencyFormat:'USD':'Billions'}}
                        </div>
                        <div class="title text-right text-md md:text-lg">Revenue</div>
                    </div>
                </div>
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.ebitda']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="balanceValue">{{financialData.ebitda | currencyFormat:'USD':'Billions'}}
                        </div>
                        <div class="title text-right text-md md:text-lg">EBITDA</div>
                    </div>
                </div>
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.net.debt']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="balanceValue">{{financialData.netDebt | currencyFormat:'USD':'Billions'}}
                        </div>
                        <div class="title text-right text-md md:text-lg">Net Debt</div>
                    </div>
                </div>
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.net.leverage']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="leverageValue">{{tvpiIrrFormat.getTvpiValue(financialData.netLeverage,
                            true)}}
                        </div>
                        <div class="title text-right text-md md:text-lg">Net Leverage</div>
                    </div>
                </div>
                <div class="pr-2.5 lg:pr-0 pl-5" *ngIf="assetType === 'Infrastructure'">
                    <div class="text-right lg:text-left">
                        <img class="icon" src="{{iconSet['icon.risk.profile']}}">
                    </div>
                    <div class="text-right lg:text-left value" data-cy="stringFormatValue">{{financialData.riskProfile | standardStringFormat}}
                    </div>
                    <div class="text-right lg:text-left title">Risk Profile</div>    
                </div>
            </div>
        </ng-container>
        <ng-container *ngIf="assetType === 'Real Estate'">
            <div class="row grid grid-cols-2 gap-10 md:grid-cols-3 lg:grid-cols-6 lg:gap-0 inner-row px-5" data-cy="realEstateContainer">
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.re.acq.price']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="balanceValue">{{financialData.aquisitionPrice | currencyFormat:'USD':'Billions'}}</div>
                        <div class="title text-right text-md md:text-lg">Aquisition Price</div>
                    </div>
                </div>
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.re.entry.loan.tovalue']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="percentageValue">{{financialData.entryLoanToValue | percentageValueFormat}}</div>
                        <div class="title text-right text-md md:text-lg">Entry Loan to Value</div>
                    </div>
                </div>
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.re.entry.cap.rate']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="percentageValue">{{financialData.entryCapRate | percentageValueFormat}}</div>
                        <div class="title text-right text-md md:text-lg">Entry Cap Rate</div>
                    </div>
                </div>
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.re.uw.exit.caprate']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="percentageValue">{{financialData.exitCapRate | percentageValueFormat}}</div>
                        <div class="title text-right text-md md:text-lg">Underwritten Cap Rate</div>
                    </div>
                </div>
                <div>
                    <div class="pr-2.5 lg:pr-0 lg:float-left lg:relative">
                        <div class="text-right">
                            <img class="icon" src="{{iconSet['icon.re.uw.disp.price']}}">
                        </div>
                        <div class="value text-right text-md md:text-lg" data-cy="balanceValue">{{financialData.underwrittenDispositionPrice | currencyFormat:'USD':'Billions'}}
                        </div>
                        <div class="title text-right text-md md:text-lg">Underwritten Disposition Price</div>
                    </div>
                </div>
                <div class="pr-2.5 lg:pr-0 pl-5">
                    <div class="right">
                        <div class="text-right lg:text-left">
                            <img class="icon" src="{{iconSet['icon.re.uw.hold.term']}}">
                        </div>
                        <div class="text-right lg:text-left value" data-cy="stringFormatValue">{{financialData.underwrittenHoldTermValue | standardStringFormat}}
                        </div>
                        <div class="text-right lg:text-left title">Underwritten Hold Term</div>
                    </div>
                </div>
            </div>
        </ng-container>
    </div>
</div>